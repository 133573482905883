import React, { useContext, useRef, useState, useEffect } from "react"
import moment from "moment"
import { Layout, Parallax, NewsletterInModal } from "@components/layout"
import { CommentSection } from "@components/blog"
import { withHtml } from "@components/logic"
import { Tags, DirectionArrows } from "@components/theme"
import { CartCtx } from "@components/contexted"
import { Modal } from "@components/shared"

import {
  Breadcrumbs,
  AnimatedSection,
  AnimatedLink  ,
} from "@components/shared"
import icon from "./img/open-book2.png"
import coffe from "./img/coffee-cup-hot.png"
import coffe2 from "./img/coffee-cup-hotx2.png"

import {
  post__wrapper,
  post__modal,
  post__content,
  post__wave,
  post__icon,
  icon__wrap,
  post__coffe,
  post__coffe__buttons,
  post__coffe__buttons__btn,
} from "./styles/post.module.scss"

function useIntersectionObserver(
  ref,
  options = {
    threshold: [1],
    rootMargin: '-200px 0px -200px 0px'
  }
) {
  const [isIntersecting, setIntersecting] = useState(false)
  useEffect(() => {
    const { current } = ref
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, options)
    console.log('cur', current)
    console.log('obs', observer)
    if (current) observer.observe(current)
    return () => {
      if (current) observer.unobserve(current)
    }
  }, [ref])
  return isIntersecting
}



const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Content = withHtml("article")
const linkModifier = ({ slug }) => `/blog/${slug}/`

const Post = ({ pageContext }) => {
  const { page, nextPost, prevPost, products } = pageContext
  const { categories, tags } = page

  const intersectingRef = useRef()
  const modalRef = useRef()

  const [mainCategory] = categories.nodes
  const coffeProduct = products.find((el) => el.productId === 374)
  const isIntersecting = useIntersectionObserver(intersectingRef)

  let modalClosed = null
  if (typeof window !== 'undefined') {
      modalClosed = localStorage.getItem("post_modal_close")
  }
  const { dispatch } = useContext(CartCtx)
  const Wave = () => {
    return (
      <div className={post__wave}>
        <svg viewBox="0 0 1200 600">
          <path
            d="M 10 380 Q 60 600 420 520 Q 895 375 1080 445 Q 1190 480 1200 595 L 0 600 A 50 50 0 1 1 0 320 "
            transform="translate(0 0)"
            fill="#fff"
          />
        </svg>
      </div>
    )
  }

  useEffect(() => {
    if(isIntersecting === true && modalClosed !== "true" ){
      modalRef.current.toggle()
    }
  },[isIntersecting])


  const Icon = () => {
    return (
      <div className={post__icon}>
        <img src={icon} alt="" />
      </div>
    )
  }

  return (
    <Layout
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <div className={post__wrapper}>
        <Parallax height={500} image={page.featuredImage} title={ page.title }
        subtitle={`Data: ${moment(page.date).format("DD / MM / YYYY")}`}
        >
          <Wave />
        </Parallax>
        <AnimatedSection>
          <Icon className={icon__wrap} />
        </AnimatedSection>
        <Breadcrumbs
          elements={[
            {
              label: "Blog",
              url: `/blog/`,
            },
            {
              label: mainCategory.name,
              url: `/blog/categories/${mainCategory.slug}`,
            },
            {
              label: page.title,
              url: `/blog/${page.slug}/`,
            },
          ]}
        />

        <Tags tags={tags.nodes} tagsUrl={"/tags"} />

        <Content className={post__content}>{page.content}</Content>

      
        <div ref={intersectingRef}>
          <Modal title={''} className={post__modal} onClose={()=>localStorage.setItem("post_modal_close", "true")}ref={modalRef}>
          <NewsletterInModal
          title="Chcesz być na bieżąco?"
          subtitle="Zapisz się do naszego newslettera, aby otrzymywać aktualności, informacje o produktach, oraz najnowsze kody rabatowe." />
          </Modal>
        </div>

        <div className={post__coffe}>
          <h4>
            Jeśli czegoś konkretnego się dowiedziałeś z tego posta, to postaw mi
            kawę, która zasili kolejne posty z materiałami dla programistów
          </h4>
          <div className={post__coffe__buttons}>
            <AnimatedLink
              onClick={() => {
                dispatch({
                  type: "addToCart",
                  payload: {
                    value: cleanPrice(coffeProduct.regularPrice),
                    regularValue: cleanPrice(coffeProduct.regularPrice),
                    quantity: 1,
                    product_id: coffeProduct.productId,
                    variation_id: null,
                    variant: null,
                    sale: false,
                    price: cleanPrice(coffeProduct.regularPrice),
                    regularPrice: cleanPrice(coffeProduct.regularPrice),
                    unit: "PLN",
                    name: coffeProduct.name,
                    slug: coffeProduct.slug,
                    image: coffeProduct.image,
                  },
                })
              }}
              to={"/shop/checkout"}
              className={post__coffe__buttons__btn}
            >
                <img src={coffe} alt=""></img>
            </AnimatedLink>
            <AnimatedLink
              onClick={() => {
                dispatch({
                  type: "addToCart",
                  payload: {
                    value: cleanPrice(coffeProduct.regularPrice),
                    regularValue: cleanPrice(coffeProduct.regularPrice),
                    quantity: 1,
                    product_id: coffeProduct.productId,
                    variation_id: null,
                    variant: null,
                    sale: false,
                    price: cleanPrice(coffeProduct.regularPrice),
                    regularPrice: cleanPrice(coffeProduct.regularPrice),
                    unit: "PLN",
                    name: coffeProduct.name,
                    slug: coffeProduct.slug,
                    image: coffeProduct.image,
                  },
                })
                dispatch({
                  type: "changeProductQty",
                  payload: {
                    product_id: coffeProduct.productId,
                    quantity: 2,
                  },
                })
              }}
              to={"/shop/checkout"}
              className={post__coffe__buttons__btn}
            >
              <img src={coffe2} alt=""></img>
            </AnimatedLink>
          </div>
        </div>
        <DirectionArrows
          prevItem={prevPost}
          nextItem={nextPost}
          linkModifier={linkModifier}
        />

        <CommentSection post={page} />
        {/*

			<h4>prevPost </h4>

			<PostItem {...prevPost}/>
			<h4>nextPost</h4>
			<PostItem {...nextPost}/>
 
			<NewsletterSection />
			*/}
      </div>
    </Layout>
  )
}

export default Post
